import * as Sentry from '@sentry/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { HydratedRouter } from 'react-router/dom'

Sentry.init({
	dsn: 'https://329a1f978bed0a5cef36198b28133139@o4507809353760768.ingest.de.sentry.io/4507809359265872',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	tracePropagationTargets: ['localhost', /^https:\/\/www\.vatomat\.cz/, /^\//],

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
})

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<HydratedRouter />
		</StrictMode>,
	)
})
